<template>
  <div>
    <b-card
      class="text-left"
      :title="`Visão geral de: ${workspace.workspace_title}`"
    >
      <!-- Totais de usuario e projetos -->
      <b-row>
        <b-col cols="12" md="8">
          <div class="infos">
            <p>
              Projetos em andamento:
              <strong>{{ item.projectsInProcess }}</strong>
            </p>
            <p>
              Projetos arquivados:
              <strong>{{ item.archivedProjects }}</strong>
            </p>
            <p>
              Usuários ativos: <strong>{{ item.users }}</strong>
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <b-button
            @click="$router.push(`/usuarios/dashboard/${member.member_id}`)"
            variant="success"
            class="cta"
          >
            Meu Painel
          </b-button>
          <b-button @click="openModalAction" variant="success" class="cta">
            + Adicionar tarefa
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Filtro">
      <!-- Inputs for date range and toggle button (all in the same row) -->
      <b-row class="align-items-end d-flex">
        <!-- INTERVALO -->
        <b-col md="3">
          <!-- start_date -->
          <b-form-group label="Data de Início">
            <b-form-input v-model="startDate" type="month" />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <!-- end_date -->
          <b-form-group label="Data de Fim">
            <b-form-input v-model="endDate" type="month" />
          </b-form-group>
        </b-col>

        <!-- Botoes  -->
        <b-col sm="6" md="3">
          <b-button
            variant="primary"
            @click="applyDateFilter"
            class="cta"
            block
          >
            Filtrar
          </b-button>
        </b-col>

        <!-- <b-col sm="6" md="3">
          <b-button variant="primary" @click="getAllPeriod" class="cta" block>
            Todo Período
          </b-button>
        </b-col> -->

        <!-- Toggle button -->
        <!-- Empilhado em telas menores -->
        <!-- <b-col sm="12" class="mt-2">
        <b-button variant="primary" @click="toggleDateFiltering" class="cta" block>
          {{ useDateRangeInputs ? 'Intervalo' : 'Mensal' }}
        </b-button>
      </b-col> -->
      </b-row>
    </b-card>

    <b-card title="Painel do escritório" style="overflow-x: auto">
      <b-tabs content-class="mt-3">
        <b-tab title="Cálculos">
          <p></p>
          <b-row class="justify-content-center">
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="background: #9bffc833; border-radius: 5px"
              v-b-tooltip.hover
              title="Somatório de todas as receitas lançadas"
            >
              <p class="text-center mt-1"><b>RECEITAS</b></p>
              <b-card class="text-center">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(item.projectRevenue)}`
                }}</strong>
                <small style="display: block">Total de Receita bruta </small>
              </b-card>
            </b-col>
            <b-col cols="12" md="1" lg="1" style="max-width: 50px !important">
              <h1 class="mt-4 text-center">-</h1>
            </b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="background: #ffc8c833; border-radius: 5px"
              v-b-tooltip.hover
              title="Valores de impostos incididos sobre a receita"
            >
              <p class="text-center mt-1"><b>CUSTOS DIRETOS</b></p>
              <b-card class="text-center">
                <strong style="display: block">{{
                  `${numberToMonetary(item.legalExpenses)}`
                }}</strong>
                <small style="display: block">Despesas Legais </small>
              </b-card>
            </b-col>
            <b-col cols="12" md="1" lg="1" style="max-width: 50px !important">
              <h1 class="mt-4 text-center">=</h1>
            </b-col>

            <b-col
              cols="12"
              md="2"
              lg="2"
              style="background: #fffdb933; border-radius: 5px"
              v-b-tooltip.hover
              title="Receita - Total despesas do projeto - Custo Direto - Despesas Legais"
            >
              <b-card class="text-center mt-2">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(
                    item.projectRevenue -
                      (item.projectTotalCost.totalGeral +
                        item.team_wages +
                        item.legalExpenses)
                  )}`
                }}</strong>
                <small>
                  {{
                    item.projectRevenue
                      ? `${(
                          ((item.projectRevenue -
                            (item.projectTotalCost.totalGeral +
                              item.team_wages +
                              item.legalExpenses)) /
                            item.projectRevenue) *
                          100
                        ).toFixed(2)}%`
                      : '0%'
                  }}</small
                >
                <small style="display: block">Margem de contribuição </small>
              </b-card>
            </b-col>
            <b-col cols="12" md="1" lg="1" style="max-width: 50px !important">
              <h1 class="mt-4 text-center">-</h1>
            </b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="background: #d9cfcf33; border-radius: 5px"
            >
              <p class="text-center mt-1"><b>CUSTOS INDIRETOS</b></p>
              <b-card class="text-center">
                <strong style="display: block">{{
                  `${numberToMonetary(item.officeTotalCost.totalRealizado)}`
                }}</strong>
                <small style="display: block">Custos de Escritório</small>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="justify-content-center">
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="background: #9bffc833; border-radius: 5px"
              v-b-tooltip.hover
              title="Receitas deduzidas dos impostos."
            >
              <b-card class="text-center">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(item.projectRevenueTax)}`
                }}</strong>
                <small style="display: block">Total de Receita líquida </small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="background: #ffc8c833"
              v-b-tooltip.hover
              title="Somatorio de custos dos colaboradores da equipe técnica"
            >
              <b-card class="text-center mt-1">
                <strong style="display: block">{{
                  `${numberToMonetary(item.team_wages)}`
                }}</strong>
                <small style="display: block">Custos da Equipe Técnica </small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col cols="12" md="2" lg="2"></b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="background: #d9cfcf33; border-radius: 5px"
            >
              <b-card class="text-center">
                <strong style="display: block">{{
                  `${numberToMonetary(item.costDepreciation)}`
                }}</strong>
                <small style="display: block">Custos de depreciação</small>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col cols="12" md="2" lg="2"></b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="background: #ffc8c833"
              v-b-tooltip.hover
              title="EST-06: Somatório de Custos Específicos de Projeto"
            >
              <b-card class="text-center">
                <strong style="display: block">{{
                  ` ${numberToMonetary(item.projectTotalCost.totalGeral)}`
                }}</strong>
                <small style="display: block"
                  >Total de despesas de projetos</small
                >
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="max-width: 50px !important"
            ></b-col>
            <b-col cols="12" md="2" lg="2"></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="background: #d9cfcf33"
            ></b-col>
          </b-row>

          <b-row class="justify-content-center">
            <b-col cols="12" md="2" lg="2"></b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col cols="12" md="2" lg="2" style="background: #ffc8c833">
              <h1 class="text-center">=</h1>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col cols="12" md="2" lg="2"></b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col cols="12" md="2" lg="2" style="background: #d9cfcf33">
              <h1 class="text-center">=</h1>
            </b-col>
          </b-row>

          <b-row class="justify-content-center">
            <b-col cols="12" md="2" lg="2"></b-col>
            <b-col cols="12" md="1" lg="1" style="max-width: 50px !important">
            </b-col>
            <b-col cols="12" md="2" lg="2" style="background: #ffc8c833">
              <b-card class="text-center">
                <strong style="display: block">{{
                  ` R$${numberToMonetary(
                    item.projectTotalCost.totalGeral +
                      item.team_wages +
                      item.legalExpenses
                  )}`
                }}</strong>
                <small style="display: block">Total de custos diretos</small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col cols="12" md="2" lg="2"></b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="background: #d9cfcf33"
              v-b-tooltip.hover
              title="Despesas Fixas necessárias  ao funcionamento da área de trabalho + Custo total de Bens Depreciados = é o custo que será relacionado ao total de horas computadas para determinação do custo/hora do escritório, este, inversamente proporcional à quantidade de horas computadas pela equipe técnica."
            >
              <b-card class="text-center">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(
                    item.officeTotalCost.totalRealizado + item.costDepreciation
                  )}`
                }}</strong>
                <small style="display: block">Total de Custo Indireto</small>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Resultado / Outros custos" active>
          <b-row class="justify-content-center">
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #b89bff33;
                border: 1px dashed #b89bff;
                border-bottom: none;
                border-radius: 5px 5px 0 0;
              "
              v-b-tooltip.hover
              title="Margem de Contribuição - Custo Indireto (Despesas Fixas)"
            >
              <p class="text-center mt-1"><b>RESULTADO</b></p>
              <b-card class="text-center">
                <strong style="display: block">{{
                  `${numberToMonetary(
                    item.projectRevenue -
                      (item.projectTotalCost.totalGeral +
                        item.team_wages +
                        item.legalExpenses) -
                      (item.officeTotalCost.totalRealizado -
                        item.costDepreciation)
                  )}`
                }}</strong>
                <small style="display: block"
                  >Resultado Operacional do escritório</small
                >
              </b-card>
            </b-col>
            <b-col cols="12" md="1" lg="1" style="max-width: 50px !important">
              <h1 class="mt-4 text-center">-</h1>
            </b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #9bffbb33;
                border: 1px dashed #9bffbb;
                border-bottom: none;
                border-radius: 5px 5px 0 0;
              "
              v-b-tooltip.hover
              title="Somatório das transações de entrada para investimento (transação com nota de descrição)"
            >
              <p class="text-center mt-1"><b>OUTROS CUSTOS</b></p>
              <b-card class="text-center">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(
                    item.otherCostsCategories[0].total_value
                  )}`
                }}</strong>
                <small style="display: block">Investimentos </small>
              </b-card>
            </b-col>
            <b-col cols="12" md="1" lg="1" style="max-width: 50px !important">
              <h1 class="mt-4 text-center">=</h1>
            </b-col>

            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #fffdb933;
                border: 1px dashed #b7aa00;
                border-bottom: none;
                border-radius: 5px 5px 0 0;
              "
            >
              <b-card class="text-center mt-2">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(
                    item.projectRevenue -
                      (item.projectTotalCost.totalGeral +
                        item.team_wages +
                        item.legalExpenses) -
                      (item.officeTotalCost.totalRealizado -
                        item.costDepreciation) -
                      (item.otherCostsCategories[0].total_value +
                        item.otherCostsCategories[1].total_value +
                        item.otherCostsCategories[2].total_value)
                  )}`
                }}</strong>
                <small style="display: block">Lucro Líquido </small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #fffdb933;
                border: 1px dashed #b7aa00;
                border-bottom: none;
                border-radius: 5px 5px 0 0;
              "
              v-b-tooltip.hover
              title="ESCRITORIO-N-03: Custo Real do Escritório dividido pelo Total de horas trabalhadas no mês."
            >
              <b-card class="text-center mt-2">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(
                    parseInt(
                      (item.officeTotalCost.totalRealizado -
                        item.costDepreciation) /
                        item.hours
                    )
                  )}`
                }}</strong>
                <small style="display: block"
                  >Custo Indireto da hora do escritório (R$/h)
                </small>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="justify-content-center">
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #b89bff33;
                border: 1px dashed #b89bff;
                border-top: none;
                border-bottom: none;
                border-radius: 5px 5px 0 0;
              "
            >
              <b-card class="text-center mt-1">
                <strong style="display: block">
                  {{
                    item.projectRevenue
                      ? `${(
                          ((item.projectRevenue -
                            (item.projectTotalCost.totalGeral +
                              item.team_wages +
                              item.legalExpenses) -
                            (item.officeTotalCost.totalRealizado -
                              item.costDepreciation)) /
                            item.projectRevenue) *
                          100
                        ).toFixed(2)}%`
                      : '0%'
                  }}
                </strong>
                <small style="display: block">Lucratividade Operacional</small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #9bffbb33;
                border: 1px dashed #9bffbb;
                border-top: none;
                border-bottom: none;
                border-radius: 5px 5px 0 0;
              "
            >
              <b-card class="text-center mt-1">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(
                    item.otherCostsCategories[1].total_value
                  )}`
                }}</strong>
                <small style="display: block"
                  >Empréstimos e Financiamento
                </small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #fffdb933;
                border: 1px dashed #b7aa00;
                border-bottom: none;
                border-top: none;
                border-radius: 5px 5px 0 0;
              "
            >
              <b-card class="text-center mt-2">
                <strong style="display: block">{{
                  `${
                    item.projectRevenue > 0
                      ? (
                          (item.projectRevenue -
                            (item.projectTotalCost.totalGeral +
                              item.team_wages +
                              item.legalExpenses) -
                            (item.officeTotalCost.totalRealizado -
                              item.costDepreciation) -
                            (item.otherCostsCategories[0].total_value +
                              item.otherCostsCategories[1].total_value +
                              item.otherCostsCategories[2].total_value)) /
                          item.otherCostsCategories[0].total_value
                        ).toFixed(2) + '%'
                      : '0%'
                  }`
                }}</strong>
                <small style="display: block">Rentabilidade Final </small>
              </b-card>
            </b-col>

            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #fffdb933;
                border: 1px dashed #b7aa00;
                border-bottom: none;
                border-top: none;
                border-radius: 5px 5px 0 0;
              "
            >
              <b-card class="text-center mt-2">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(
                    (item.projectTotalCost.totalGeral +
                      item.team_wages +
                      item.legalExpenses) /
                      item.hours
                  )}`
                }}</strong>
                <small style="display: block"
                  >Custo direto da hora do escritório (R$/h)
                </small>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #b89bff33;
                border: 1px dashed #b89bff;
                border-top: none;
                border-radius: 5px 5px 0 0;
              "
            >
              <b-card class="text-center">
                <strong style="display: block">{{
                  ` ${numberToMonetary(item.projectTotalCost.totalGeral)}`
                }}</strong>
                <small style="display: block">Rentabilidade Operacional</small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #9bffbb33;
                border: 1px dashed #9bffbb;
                border-top: none;
                border-bottom: none;
                border-radius: 5px 5px 0 0;
              "
            >
              <b-card class="text-center">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(
                    item.otherCostsCategories[2].total_value
                  )}`
                }}</strong>
                <small style="display: block">Distribuição de Lucros</small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #fffdb933;
                border: 1px dashed #b7aa00;
                border-bottom: none;
                border-top: none;
                border-radius: 5px 5px 0 0;
              "
              v-b-tooltip.hover
              title="EST-06: Somatório de total de custos diretos e indiretos relativos às horas computadas pela equipe técnica"
            >
              <b-card class="text-center mt-2">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(item.projectTasksTotalCost)}`
                }}</strong>
                <small style="display: block">Custo total (tarefas) </small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #fffdb933;
                border: 1px dashed #b7aa00;
                border-top: none;
                border-radius: 5px 5px 0 0;
              "
              v-b-tooltip.hover
              title="Esse custo se refere ao valor específico de cada mês. No painel POR PERÍODO o custo deve ser representado através do cálculodo custo médio de horas diretas e indiretas."
            >
              <b-card class="text-center mt-2">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(
                    (item.projectTotalCost.totalGeral +
                      item.team_wages +
                      item.legalExpenses) /
                      item.hours +
                      (item.officeTotalCost.totalRealizado -
                        item.costDepreciation) /
                        item.hours
                  )}`
                }}</strong>
                <small style="display: block"
                  >Custo Real da Hora do Escritório no Mês (R$/h)
                </small>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="justify-content-center">
            <b-col cols="12" md="2" lg="2"></b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #9bffbb33;
                border: 1px dashed #9bffbb;
                border-top: none;
                border-bottom: none;
                border-radius: 5px 5px 0 0;
              "
            >
              <h1 class="text-center">=</h1>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #fffdb933;
                border: 1px dashed #b7aa00;
                border-bottom: none;
                border-top: none;
                border-radius: 5px 5px 0 0;
              "
            ></b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col cols="12" md="2" lg="2"> </b-col>
          </b-row>

          <b-row class="justify-content-center">
            <b-col cols="12" md="2" lg="2"></b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #9bffbb33;
                border: 1px dashed #9bffbb;
                border-top: none;
                border-radius: 5px 5px 0 0;
              "
            >
              <b-card class="text-center">
                <strong style="display: block">{{
                  `R$ ${numberToMonetary(
                    item.otherCostsCategories[0].total_value +
                      item.otherCostsCategories[1].total_value +
                      item.otherCostsCategories[2].total_value
                  )}`
                }}</strong>
                <small style="display: block">Total de Outros Custos</small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col
              cols="12"
              md="2"
              lg="2"
              style="
                background: #fffdb933;
                border: 1px dashed #b7aa00;
                border-top: none;
                border-radius: 5px 5px 0 0;
              "
              v-b-tooltip.hover
              title="Receita necessária para igualar os gastos"
            >
              <b-card class="text-center mt-2">
                <strong style="display: block">{{
                  item.projectRevenue
                    ? `R$ ${numberToMonetary(
                        (item.officeTotalCost.totalRealizado +
                          item.costDepreciation) /
                          ((item.projectRevenue -
                            (item.projectTotalCost.totalGeral +
                              item.team_wages +
                              item.legalExpenses)) /
                            item.projectRevenue)
                      )}`
                    : '0%'
                }}</strong>
                <small style="display: block">Ponto de Equiliibrio </small>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="1"
              lg="1"
              style="max-width: 50px !important"
            ></b-col>
            <b-col cols="12" md="2" lg="2"></b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>

    <!-- <b-card>
    
      <b-col cols="12">
        <b-row class="d-flex justify-content-around">
          <div class="dashboard-item text-center">
            <strong>{{ ` ${numberToMonetary(item.projectCosts)}` }}</strong>
            <small>Total de despesas de projeto </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ ` ${numberToMonetary(item.legalExpenses)}` }}</strong>
            <small>Total de despesas legais (impostos) </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `${numberToMonetary(item.projectRevenue)}` }}</strong>
            <small>Total de receitas </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `${numberToMonetary(item.team_wages)}` }}</strong>
            <small>Custo Direto </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ ` ${numberToMonetary(item.inteam_wages)}` }}</strong>
            <small>despesa fixa </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{
              `${numberToMonetary(item.projectTasksTotalCost)}`
            }}</strong>
            <small>Custo Total (tarefas) </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `${numberToMonetary(item.projectTotalCost)}` }}</strong>
            <small>Custo Total (projetos) </small>
          </div>

          
          <div class="dashboard-item text-center">
            <strong>{{
              ` ${numberToMonetary(item.contributionMargin)}`
            }}</strong>
            <small>Margem de contribuição </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `${numberToMonetary(item.officeTotalCost.totalRealizado)}` }}</strong>
            <small>Custo total de escritório </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `${numberToMonetary(item.costDepreciation)}` }}</strong>
            <small>Custo de depreciação </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `${numberToMonetary(item.realCostOffice)}` }}</strong>
            <small>Custo real do escritório</small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{
              `${
                (item.officeTotalCost.totalRealizado > 0 && item.hours > 0) ||
                item.costDepreciation > 0
                  ? numberToMonetary(
                      item.officeTotalCost.totalRealizado / item.hours + item.costDepreciation
                    )
                  : '0'
              }`
            }}</strong>
            <small>Custo real da hora</small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `${item.hours}h` }}</strong>
            <small>Total de horas trabalhadas</small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{
              `${item.profitability > 0 ? item.profitability : 0}%`
            }}</strong>
            <small>Lucratividade</small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{
              `${
                item.economicBreakEvenPoint > 0
                  ? numberToMonetary(item.economicBreakEvenPoint)
                  : '(Margem negativa)'
              }`
            }}</strong>
            <small>Ponto de equilíbrio financeiro (receita bruta mínima)</small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{
              `${
                signalNumber(item.projectResult) == false ? '-' : ''
              } ${numberToMonetary(
                signalNumber(item.projectResult) == false
                  ? item.projectResult * -1
                  : item.projectResult
              )}`
            }}</strong>
            <small>Resultado do escritório</small>
          </div>
        </b-row>
      </b-col>

    </b-card> -->
    <Modal @closeModal="closeModal" :open="myTasksModal" />

    <!-- DASHBOARDS -->

    <b-card v-if="hoursPerEmployee_data.render" class="overflow-scroll-mobile">
      <b-row>
        <b-col
          :lg="costPerProject_data.chartSeries[0].data.length < 5 ? 6 : 12"
          :md="costPerProject_data.chartSeries[0].data.length < 5 ? 6 : 12"
          class="text-center mx-auto w-10 h-50 mb-2"
        >
          <label>Horas por colaborador</label><br />
          <label>{{ `${item.hours}h 00m` }}</label>
          <ProfitabilityChart :chartData="hoursPerEmployee_data"
        /></b-col>
        <b-col
          :lg="costPerProject_data.chartSeries[0].data.length < 5 ? 6 : 12"
          :md="costPerProject_data.chartSeries[0].data.length < 5 ? 6 : 12"
          class="text-center mx-auto w-10 h-50"
        >
          <label>Custo por projeto</label>
          <ProfitabilityChart :chartData="costPerProject_data"
        /></b-col>
      </b-row>
    </b-card>

    <b-card v-if="costPerProject_data.render" class="overflow-scroll-mobile" title="Fluxo financeiro">
      <b-col class="text-center mx-auto w-10 h-50">
        <label></label>
        <CashFlow type="line" height="400" :chartData="cashFlow_data" />
      </b-col>
    </b-card>

    <b-card v-if="costPerOffice_data.render" class="overflow-scroll-mobile">
      <b-col class="text-center mx-auto w-10 h-50">
        <label>Custo por ano</label>
        <ProfitabilityChart :chartData="costPerOffice_data"
      /></b-col>
    </b-card>
  </div>
</template>

<script>
import {
  VBTooltip,
  BCol,
  BRow,
  BCard,
  BFormGroup,
  BButton,
  BFormInput,
  BTab,
  BTabs,
} from 'bootstrap-vue';
import ProfitabilityChart from '@/components/Chart.vue';
import CashFlow from '@/components/CashFlow.vue';
import Modal from '@/views/pages/manager/myTasks/Modal.vue';
import moment from 'moment';

//import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCol,
    BRow,
    BCard,
    ProfitabilityChart,
    CashFlow,
    BFormGroup,
    BButton,
    BFormInput,
    Modal,
    BTab,
    BTabs,
  },
  data: () => ({
    item: {
      projectCosts: 0,
      legalExpenses: 0,
      projectRevenue: 0,
      team_wages: 0,
      inteam_wages: 0,
      projectTasksTotalCost: 0,
      projectTotalCost: 0,
      contributionMargin: 0,
      officeTotalCost: 0,
      costDepreciation: 0,
      realCostOffice: 0,
      realCostHour: 0,
      hours: 0,
      profitability: 0,
      economicBreakEvenPoint: 0,
      projectResult: 0,
      projectsInProcess: 0,
      archivedProjects: 0,
      users: 0,
    },

    member: {},
    selectedMonth: 'Janeiro',
    selectedYear: 2024,
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    years: [2020, 2021, 2022, 2023, 2024, 2025],

    useDateRangeInputs: false,
    startDate: '',
    endDate: '',
    dispatch_start_date: '',
    dispatch_end_date: '',
    project_name: 'Projeto',
    workspace: { workspace_title: '' },
    myTasksModal: false,
    hoursPerEmployee_data: { render: false },
    costPerProject_data: { render: false },
    costPerOffice_data: { render: false },
    cashFlow_data: { render: false },
    cashFlowResp: [],
  }),

  created() {
    this.getCurrentMonth();
    this.updateDates();
    this.getItem();
    this.getCashFlow();
    this.getMemberInfo();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },

    start_date() {
      const monthIndex = this.months.indexOf(this.selectedMonth) + 1;
      return this.useDateRangeInputs
        ? this.startDate
        : new Date(this.selectedYear, monthIndex - 1, 1)
            .toISOString()
            .split('T')[0];
    },
    end_date() {
      const monthIndex = this.months.indexOf(this.selectedMonth) + 1;
      if (this.useDateRangeInputs) {
        return this.endDate;
      } else {
        const startDate = new Date(this.selectedYear, monthIndex - 1, 1);
        const endDayOfMonth = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        );
        return endDayOfMonth.toISOString().split('T')[0];
      }
    },
  },

  methods: {
    async getCashFlow() {
      this.cashFlowResp = [];
      this.$store
        .dispatch('getCashFlowDashboard', {
          workspace_id: this.$store.getters.currentWorkspace.id,
          start_date: this.dispatch_start_date,
          end_date: this.dispatch_end_date,
        })
        .then((resp) => {
          this.cashFlowResp = resp;
          this.setCashFlow_data();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getItem() {
      this.$store
        .dispatch('getWorkspace', {
          id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.workspace = resp;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCurrentMonth() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      this.startDate = `${year}-${month}`;
      this.endDate = `${year}-${month}`;

      this.dispatch_start_date = `${year}-${month}`;
      this.dispatch_end_date = `${year}-${month}`;
    },

    async getMemberInfo() {
      this.$store
        .dispatch('getUserMember', {
          id: this.$store.getters.user.id,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          this.member = resp[0];
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getAll() {
      this.item = {};
      this.$store
        .dispatch('getDashboard', {
          start_date: this.dispatch_start_date,
          end_date: this.dispatch_end_date,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then(async (resp) => {
          this.item = {
            ...resp.resumeDashboard,
          };

          this.setHoursPerEmployee_data(resp.employeeHours);
          this.setCostPerProject_data(resp.projectsCosts);
          this.setcostPerOffice_data(resp.OfficeTransactionsByYear);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getAllPeriod() {
      const anoAtual = new Date().getFullYear();
      const startDate = new Date(anoAtual, 0, 1);
      const formattedStartDate = startDate.toISOString().split('T')[0];

      const endDate = new Date(anoAtual, 11, 31);
      const formattedEndDate = endDate.toISOString().split('T')[0];

      this.dispatch_start_date = formattedStartDate;
      this.dispatch_end_date = formattedEndDate;

      this.$toast.success('Selecionado todo o periodo.', {
        position: 'top-right',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      });

      await this.getAll();
    },

    async updateDates() {
      const startDateWithDay = `${this.startDate}-01`;
      const endDateYear = parseInt(this.endDate.substring(0, 4));
      const endDateMonth = parseInt(this.endDate.substring(5, 7));

      const lastDayOfMonth = new Date(endDateYear, endDateMonth, 0).getDate();

      const formattedMonth =
        endDateMonth < 10 ? `0${endDateMonth}` : endDateMonth;

      const endDateWithLastDay = `${endDateYear}-${formattedMonth}-${lastDayOfMonth}`;

      this.dispatch_start_date = startDateWithDay;
      this.dispatch_end_date = endDateWithLastDay;
      this.getAll();
      this.getCashFlow();
    },
    padMonth(month) {
      return month.toString().padStart(2, '0');
    },
    getMonthNumber(monthName) {
      const months = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ];
      return months.indexOf(monthName) + 1;
    },

    toggleDateFiltering() {
      this.useDateRangeInputs = !this.useDateRangeInputs;
    },

    applyDateFilter() {
      this.$toast.success('Filtrado por período.', {
        position: 'top-right',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      });

      this.updateDates();
    },

    async setHoursPerEmployee_data(props) {
      const data = [];
      const date = [];
      const member_ids = [];

      props.map((e) => {
        data.push(e.hour);
        date.push(e.username);
        member_ids.push(e.member_id);
      });

      this.hoursPerEmployee_data = {
        render: date.length > 0,
        width: '100%',
        height: '300',
        chartOptions: {
          chart: {
            type: 'bar',
            events: {
              dataPointSelection: (event, chartContext, config) => {
                const dataIndex = config.dataPointIndex;
                const member_id = member_ids[dataIndex];

                if (member_id) {
                  this.$router.push(`/usuarios/dashboard/${member_id}`);
                }
              },
            },
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: function (val) {
                return val ? `${val}h` : '0h';
              },
            },
          },
          legend: {
            show: false,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
              columnWidth: '70%',
              barHeight: '80%',
              distributed: true,
            },
            dropShadow: {
              enabled: true,
              top: 0,
              left: 0,
              blur: 3,
              opacity: 0.5,
            },
            dataLabels: {
              hideOverflowingLabels: true,
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return `${val}h`;
            },
          },
          xaxis: {
            tickAmount: date.length > 5 ? 5 : date.length,
            categories: date,
            labels: {
              rotate: -45,
              trim: false,
              style: {
                fontSize: '12px',
                fontWeight: 'bold',
              },
            },
          },
        },
        chartSeries: [
          {
            name: 'Horas',
            data,
          },
        ],
      };
    },

    async setCashFlow_data() {
      this.cashFlow_data = {
        render: true,
        width: '100%',
        height: 400,

        chartOptions: {
          chart: {
            type: 'line',
            stacked: true,
          },
          xaxis: {
            categories: this.cashFlowResp.categories,
            labels: {
              formatter: function (value) {
                return moment(value, 'YYYY-MM').format('MMM YYYY'); // Exemplo: "Abr 2024"
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                let value = val / 100;
                return `R$ ${value.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`;
              },
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
            },
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: function (val) {
                let value = val / 100;
                return `R$ ${value.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`;
              },
            },
          },
          colors: ['#28a745', '#dc3545', '#ffd147', '#6c757d'], // Adicionando cor para Outras Despesas
          stroke: {
            width: [0, 0, 0, 3], // Linha apenas para o saldo
          },
          markers: {
            size: [0, 0, 0, 5], // Marcador apenas no saldo
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              let value = val / 100;
              return `R$ ${value.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`;
            },
          },
        },
        chartSeries: this.cashFlowResp.chartSeries,
      };
    },

    async setCostPerProject_data(props) {
      const data = [];
      const date = [];
      const project_ids = [];

      props.forEach((e) => {
        data.push(e.project_cost + e.legalExpenses / 100);
        date.push(e.project_name);
        project_ids.push(e.project_id);
      });
      const baseHeight = 300;
      const extraHeight = 20;
      const calculatedHeight = baseHeight + data.length * extraHeight;

      this.costPerProject_data = {
        render: date.length > 0,
        width: '100%',
        height: Math.min(calculatedHeight, 800),

        chartOptions: {
          chart: {
            type: 'bar',
            events: {
              dataPointSelection: (event, chartContext, config) => {
                const dataIndex = config.dataPointIndex;
                const project_id = project_ids[dataIndex];

                if (project_id) {
                  this.$router.push(`/projetos/dashboard/${project_id}`);
                }
              },
            },
          },
          legend: {
            show: false, // Remove a legenda de cores desnecessária
          },
          plotOptions: {
            bar: {
              borderRadius: 6,
              horizontal: true,
              columnWidth: '50%', // Melhor espaçamento entre as barras
              distributed: true, // Deixa os dados agrupados corretamente
            },
          },

          dataLabels: {
            enabled: true,

            formatter: function (val) {
              let value = val / 100;
              return `R$ ${value.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`;
            },
            style: {
              fontSize: '10px',
              fontWeight: 'regular',
              colors: ['#000'],
            },
          },
          xaxis: {
            categories: date,
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return `${val.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`;
              },
            },
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: function (val) {
                let value = val / 100;
                return `R$ ${value.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`;
              },
            },
          },
        },
        chartSeries: [
          {
            name: 'Custo do Projeto',
            data,
          },
        ],
      };
    },

    async setcostPerOffice_data(props) {
      const data = [];
      const date = [];

      props.map((e) => {
        data.push(e.totalCost);
        date.push(e.year);
      });

      this.costPerOffice_data = {
        render: date.length > 0,
        width: '100%',
        height: 300,
        chartOptions: {
          chart: {
            type: 'bar',
            events: {
              dataPointSelection: () => {
                this.$router.push('/lancamento-de-receita');
              },
            },
          },

          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '200px',
              distributed: true,
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              let value = val / 100;

              return `R$ ${value.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}`;
            },
          },
          yaxis: {
            categories: data,
          },
          xaxis: {
            tickAmount: 3,
            labels: {
              formatter: function (number) {
                if (isNaN(number)) {
                  return 'Invalid input';
                }
                if (number === 0) {
                  return 'R$ 0,00';
                }
                if (number < 100) {
                  const formattedCents = number.toFixed(2).padStart(5, '0');
                  return `0,${formattedCents}`;
                }
                const [integerPart, decimalPart] = (number / 100)
                  .toFixed(2)
                  .toString()
                  .split('.');
                const formattedIntegerPart = integerPart.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                );
                const result = ` ${formattedIntegerPart},${decimalPart}`;
                return result;
              },
            },
            categories: date,
          },
          tooltip: {
            x: { show: true },
            y: {
              formatter: function (number) {
                if (isNaN(number)) {
                  return 'Invalid input';
                }
                if (number === 0) {
                  return 'R$ 0,00';
                }
                if (number < 100) {
                  const formattedCents = number.toFixed(2).padStart(5, '0');
                  return `0,${formattedCents}`;
                }
                const [integerPart, decimalPart] = (number / 100)
                  .toFixed(2)
                  .toString()
                  .split('.');
                const formattedIntegerPart = integerPart.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                );
                const result = ` ${formattedIntegerPart},${decimalPart}`;
                return result;
              },
            },
          },
        },
        chartSeries: [
          {
            name: 'Custo',
            data,
          },
        ],
      };
    },

    openModalAction() {
      this.myTasksModal = true;
    },
    closeModal() {
      this.myTasksModal = false;
    },
  },
};
</script>
<style scoped>
.cta {
  width: 100%;
  margin-bottom: 10px;
}
.infos {
  display: flex;
  flex-direction: column;
}
.dashboard-item {
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  text-align: start;
  width: 200px;
}

.overflow-scroll-mobile {
  overflow-x: auto;
  white-space: wrap;
}
</style>
